import useAppContext from 'app/store/useAppContext'
import { SubmitAffirmationModal } from 'features/affirmation/SubmitAffirmationModal'
import classNames from 'shared/lib/class-names'
import ChessImage from 'shared/assets/images/promotion/chess.webp'
import BullImage from 'shared/assets/images/promotion/bull.webp'
import BullMobileImage from 'shared/assets/images/promotion/bull-mobile.webp'
import MapImage from 'shared/assets/images/about/bg.webp'
import AmxImage from 'shared/assets/images/promotion/amx.webp'
import LondonStockImage from 'shared/assets/images/promotion/londonStock.webp'
import NasdaqImage from 'shared/assets/images/promotion/nasdaq.webp'
import UkranianImage from 'shared/assets/images/promotion/ukranian.webp'

import cls from './Promotion.module.scss'

export default function Promotion() {
  const {
    store: { isMobile },
  } = useAppContext()

  return (
    <section className={classNames('flex__col_center', cls.page)}>
      <div className={classNames('container__desktop', cls.page__container)}>
        {!isMobile && (
          <>
            <img className={cls.left_logo} src={ChessImage} alt="ChessImage" />
            <img className={cls.right_logo} src={BullImage} alt="BullImage" />
          </>
        )}
        {isMobile && (
          <>
            <picture className={cls.bg_map}>
              {isMobile && <img src={MapImage} alt="MapImage" />}
            </picture>

            <div className={cls.top_block}>
              <img src={BullMobileImage} alt="BullMobileImage" />
            </div>
          </>
        )}
        <div className={cls.content}>
          <h1 className={cls.title}>
            <p>ИНВЕСТИРУЙТЕ от 100 000 драм</p>
            <p>
              И ПОЛУЧАЙТЕ ОТ <span>450 000 ДРАМ</span> В МЕСЯЦ
            </p>
          </h1>
          <p className={cls.desc}>
            Платформа для заработка с помощью торгового робота FREEDOM.AI
          </p>
          <SubmitAffirmationModal />

          {!isMobile && (
            <div className={cls.partners}>
              <p className={cls['partners__title']}>
                Наши официальные партнеры
              </p>
              <div className={cls['partners__icons']}>
                <img src={AmxImage} alt="AmxImage" />
                <img src={LondonStockImage} alt="LondonStockImage" />
                <img src={NasdaqImage} alt="NasdaqImage" />
                <img src={UkranianImage} alt="UkranianImage" />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
